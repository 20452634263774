import React from 'react'
import FlatSVG from './FlatSVG'

const Periodontal = props => (
  <FlatSVG {...props}>
    <title>periodontal</title>
    <g>
      <path d="M495.462,15.892C485.214,5.644,471.588,0,457.095,0c-14.495,0-28.121,5.645-38.371,15.894L183.36,251.263    c-3.91,3.91-3.91,10.252,0.001,14.161l24.204,24.204L78.631,418.562c-1.877,1.878-2.932,4.424-2.932,7.081v66.33H34.859    l4.624-4.624c3.911-3.911,3.911-10.251,0-14.162c-3.91-3.91-10.251-3.91-14.161,0l-21.718,21.72    c-2.863,2.864-3.721,7.171-2.17,10.913c1.55,3.741,5.2,6.181,9.251,6.181h75.027c5.531,0,10.014-4.483,10.014-10.014v-72.195    l126-126.001l24.208,24.208c1.956,1.955,4.518,2.932,7.081,2.932s5.125-0.978,7.081-2.932L495.461,92.63    C516.618,71.473,516.619,37.05,495.462,15.892z M253.013,306.756l-48.412-48.411l120.457-120.459h96.824L253.013,306.756z     M441.91,117.858h-96.824l20.233-20.234l96.826-0.001L441.91,117.858z M482.128,77.596l-96.784,0.001l47.542-47.542    c6.466-6.467,15.065-10.029,24.209-10.029c9.144,0,17.741,3.562,24.207,10.026C494.364,43.117,494.633,64.192,482.128,77.596z" />
    </g>
    <g>
      <path d="M352.972,179.653c-3.91-3.909-10.25-3.91-14.16,0l-43.94,43.939c-3.911,3.91-3.911,10.251,0,14.161    c1.955,1.955,4.518,2.932,7.081,2.932c2.563,0,5.125-0.978,7.081-2.932l43.941-43.94    C356.882,189.904,356.882,183.563,352.972,179.653z" />
    </g>
    <g>
      <path d="M290.43,242.195c-3.91-3.909-10.25-3.909-14.161-0.001l-29.318,29.318c-3.911,3.911-3.911,10.251-0.001,14.162    c1.956,1.955,4.518,2.932,7.081,2.932c2.563,0,5.125-0.978,7.081-2.932l29.319-29.318    C294.341,252.445,294.342,246.105,290.43,242.195z" />
    </g>
  </FlatSVG>
)

export default Periodontal
