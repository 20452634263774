import React from 'react'
import FlatSVG from './FlatSVG'

const Whitening = props => (
  <FlatSVG {...props} viewBox="-66 0 467 467.123">
    <title>whitening</title>
    <path d="m161.628906 117.929688c-13.925781 0-27.84375-2.097657-41.671875-6.292969l-35.101562-10.640625c-9.003907-2.726563-17.773438-3.1875-26.054688-1.371094-2.695312.585938-5.363281-1.117188-5.953125-3.8125-.59375-2.699219 1.113282-5.367188 3.8125-5.957031 9.972656-2.183594 20.433594-1.65625 31.09375 1.570312l35.101563 10.640625c25.730469 7.804688 51.816406 7.804688 77.542969 0l35.105468-10.640625c13.890625-4.207031 27.253906-3.851562 39.714844 1.054688 2.566406 1.011719 3.832031 3.917969 2.816406 6.484375-1.011718 2.570312-3.917968 3.832031-6.480468 2.820312-10.316407-4.0625-21.46875-4.328125-33.152344-.789062l-35.101563 10.640625c-13.824219 4.195312-27.75 6.292969-41.671875 6.292969zm0 0" />
    <path d="m229.476562 467.121094h-.191406c-9.367187-.046875-17.296875-7.519532-18.023437-17.015625-4.894531-63.871094-28.757813-141.894531-49.632813-141.894531s-44.738281 78.023437-49.632812 141.898437c-.726563 9.492187-8.652344 16.964844-18.046875 17.011719h-.164063c-16.71875 0-31.8125-12.355469-35.882812-29.386719l-53.953125-225.597656c-3.9687502-16.59375-4.90625-33.160157-2.78125-49.238281.359375-2.738282 2.875-4.664063 5.609375-4.304688 2.738281.359375 4.664062 2.875 4.304687 5.609375-1.960937 14.863281-1.089843 30.207031 2.59375 45.609375l53.949219 225.59375c2.964844 12.378906 14.207031 21.714844 26.15625 21.714844h.117188c4.226562-.019532 7.796874-3.4375 8.128906-7.773438 2.410156-31.5 9.6875-68.535156 18.988281-96.652344 11.957031-36.15625 25.621094-54.484374 40.613281-54.484374 14.988282 0 28.652344 18.328124 40.609375 54.480468 9.300781 28.117188 16.578125 65.148438 18.992188 96.652344.332031 4.339844 3.902343 7.757812 8.128906 7.777344h.113281c11.953125 0 23.195313-9.335938 26.15625-21.714844l42.589844-178.089844c.644531-2.683594 3.34375-4.347656 6.027344-3.699218 2.6875.640624 4.34375 3.339843 3.699218 6.023437l-42.589843 178.09375c-4.070313 17.027344-19.164063 29.386719-35.878907 29.386719zm0 0" />
    <path d="m15.203125 137.027344c-.71875 0-1.449219-.152344-2.140625-.480469-2.496094-1.1875-3.558594-4.167969-2.371094-6.664063 4.667969-9.835937 10.648438-18.199218 17.769532-24.859374 2.015624-1.882813 5.179687-1.777344 7.066406.238281 1.886718 2.015625 1.78125 5.179687-.238282 7.066406-6.199218 5.800781-11.4375 13.148437-15.566406 21.847656-.855468 1.800781-2.648437 2.851563-4.519531 2.851563zm0 0" />
    <path d="m114.789062 278.023438c-2.398437 0-4.460937-1.703126-4.910156-4.058594-6.160156-32.101563-10.105468-36.042969-42.207031-42.203125-2.351563-.453125-4.058594-2.515625-4.058594-4.910157 0-2.398437 1.703125-4.460937 4.058594-4.914062 32.101563-6.160156 36.046875-10.101562 42.207031-42.203125.449219-2.355469 2.511719-4.058594 4.910156-4.058594 2.398438 0 4.457032 1.703125 4.910157 4.058594 6.160156 32.101563 10.105469 36.042969 42.203125 42.203125 2.355468.453125 4.058594 2.515625 4.058594 4.914062 0 2.394532-1.699219 4.457032-4.058594 4.910157-32.097656 6.160156-36.042969 10.101562-42.203125 42.203125-.453125 2.355468-2.511719 4.058594-4.910157 4.058594zm-25.261718-51.171876c13.84375 4.691407 20.566406 11.417969 25.261718 25.257813 4.691407-13.84375 11.417969-20.566406 25.257813-25.257813-13.839844-4.695312-20.5625-11.417968-25.257813-25.257812-4.691406 13.839844-11.417968 20.5625-25.261718 25.257812zm0 0" />
    <path d="m172.6875 84.757812c-2.398438 0-4.460938-1.703124-4.910156-4.058593-4.929688-25.671875-7.738282-28.484375-33.410156-33.410157-2.355469-.449218-4.058594-2.511718-4.058594-4.910156 0-2.398437 1.703125-4.457031 4.058594-4.910156 25.671874-4.925781 28.480468-7.738281 33.410156-33.410156.449218-2.355469 2.511718-4.058594 4.910156-4.058594 2.394531 0 4.457031 1.703125 4.910156 4.058594 4.925782 25.671875 7.734375 28.484375 33.410156 33.410156 2.351563.453125 4.054688 2.511719 4.054688 4.910156 0 2.398438-1.703125 4.457032-4.054688 4.910156-25.675781 4.925782-28.484374 7.738282-33.410156 33.410157-.453125 2.355469-2.511718 4.058593-4.910156 4.058593zm-17.597656-42.378906c9.070312 3.539063 14.058594 8.527344 17.597656 17.597656 3.539062-9.070312 8.523438-14.058593 17.597656-17.597656-9.070312-3.539062-14.058594-8.523437-17.597656-17.597656-3.539062 9.074219-8.527344 14.058594-17.597656 17.597656zm0 0" />
    <path d="m310.652344 224.820312c-2.398438 0-4.460938-1.703124-4.910156-4.054687-8.136719-42.390625-13.34375-47.597656-55.730469-55.734375-2.355469-.449219-4.058594-2.511719-4.058594-4.910156 0-2.398438 1.699219-4.457032 4.058594-4.910156 42.386719-8.132813 47.59375-13.34375 55.730469-55.730469.449218-2.355469 2.511718-4.058594 4.910156-4.058594 2.398437 0 4.457031 1.703125 4.910156 4.058594 8.132812 42.386719 13.339844 47.597656 55.730469 55.730469 2.355469.453124 4.058593 2.511718 4.058593 4.910156 0 2.398437-1.703124 4.457031-4.058593 4.910156-42.390625 8.136719-47.597657 13.34375-55.730469 55.734375-.453125 2.351563-2.511719 4.054687-4.910156 4.054687zm-37.402344-64.699218c21.988281 6.398437 31.003906 15.414062 37.402344 37.40625 6.394531-21.992188 15.410156-31.007813 37.402344-37.40625-21.992188-6.394532-31.007813-15.410156-37.402344-37.402344-6.398438 21.992188-15.414063 31.007812-37.402344 37.402344zm0 0" />
  </FlatSVG>
)

export default Whitening
