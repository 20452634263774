import React from 'react'
import FlatSVG from './FlatSVG'

const Crown = props => (
  <FlatSVG {...props}>
    <title>crown</title>
    <g>
      <path d="M474.205,288.331l-77.6-22.168c-11.371-3.249-23.518-2.443-34.36,2.28c2.72-8.728,5.208-17.6,7.16-26.736    c0.928-4.32-1.822-8.574-6.142-9.502c-0.542-0.117-1.096-0.176-1.65-0.178h-27.352l-11.456-45.832    c-2.676-10.681-12.277-18.171-23.288-18.168H180.509c-11.014-0.002-20.615,7.492-23.288,18.176l-11.456,45.824h-27.352    c-4.418-0.001-8.001,3.579-8.002,7.998c0,0.565,0.06,1.129,0.178,1.682c1.952,9.128,4.44,18.016,7.2,26.744    c-10.854-4.729-23.015-5.538-34.4-2.288l-77.6,22.168c-3.425,0.991-5.781,4.13-5.776,7.696v176c0,4.418,3.582,8,8,8h464    c4.418,0,8-3.582,8-8v-176C480.014,292.452,477.643,289.31,474.205,288.331z M152.013,248.027c3.672-0.001,6.871-2.501,7.76-6.064    l12.968-51.872c0.889-3.565,4.093-6.067,7.768-6.064h119.008c3.672-0.004,6.876,2.494,7.768,6.056l10.488,41.944h-141.76v16    h175.504c-2.541,10.212-5.66,20.271-9.344,30.128l-5.656,15.056c-0.283,0.771-0.448,1.58-0.488,2.4l-6.608,118.92    c-0.578,10.247-9.353,18.086-19.6,17.509c-0.7-0.039-1.397-0.118-2.088-0.237c-8.164-1.683-14.346-8.383-15.368-16.656    l-16.872-112.576c-1.48-9.728-6.877-18.426-14.936-24.072c-6.002-4.223-13.165-6.484-20.504-6.472    c-2.511,0.014-5.015,0.282-7.472,0.8c-14.909,3.386-26.15,15.673-28.2,30.824l-16.888,112.56    c-1.471,9.876-10.494,16.816-20.416,15.704c-9.325-1.444-16.289-9.343-16.552-18.776l-6.512-117.56    c-0.04-0.82-0.205-1.629-0.488-2.4l-5.656-15.048c-3.687-9.85-6.812-19.9-9.36-30.104H152.013z M464.013,464.027h-448V302.059    l71.8-20.512c15.732-4.467,32.463,2.537,40.32,16.88l6.4,115.6c0.285,18.899,15.715,34.055,34.616,34    c17.115,0.019,31.656-12.511,34.168-29.44l16.888-112.6c1.038-8.52,7.25-15.504,15.592-17.528    c10.742-2.314,21.326,4.518,23.64,15.26c0.088,0.407,0.162,0.816,0.224,1.228l16.872,112.544    c2.103,15.132,13.629,27.233,28.64,30.072c18.83,3.149,36.647-9.563,39.796-28.393c0.209-1.252,0.35-2.515,0.42-3.783    l6.504-116.96c7.862-14.338,24.588-21.341,40.32-16.88l71.8,20.512V464.027z" />
    </g>
    <g>
      <path d="M308.266,0.038c-9.163,0.001-18.23,1.862-26.653,5.469c-26.56,11.328-56.592,11.328-83.152,0    c-34.378-14.753-74.206,1.157-88.959,35.535c-3.623,8.443-5.491,17.534-5.489,26.721c0.025,20.82,2.239,41.579,6.608,61.936    c0.786,3.679,4.03,6.313,7.792,6.328h243.2c3.774,0,7.035-2.637,7.824-6.328c4.358-20.358,6.562-41.117,6.576-61.936    C376.007,30.353,345.676,0.032,308.266,0.038z M355.037,120.027H124.989c-3.292-17.227-4.958-34.725-4.976-52.264    c0.013-28.586,23.198-51.749,51.784-51.736c6.994,0.003,13.914,1.424,20.344,4.176c30.581,13.048,65.163,13.048,95.744,0    c26.28-11.249,56.703,0.936,67.952,27.216c2.752,6.43,4.173,13.35,4.176,20.344C359.996,85.302,358.33,102.8,355.037,120.027z" />
    </g>
    <g>
      <path d="M164.013,33.083c-16.868,8.937-27.58,26.3-28,45.384v9.56h16v-9.6c0.48-13.343,8.128-25.387,20-31.496L164.013,33.083z" />
    </g>
  </FlatSVG>
)

export default Crown
