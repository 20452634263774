import React from 'react'
import FlatSVG from './FlatSVG'

const Filling = props => (
  <FlatSVG {...props} viewBox="-40 -30 512 512">
    <title>filling</title>
    <g xmlns="http://www.w3.org/2000/svg">
      <path d="M214.346,102.614c-19.534,0-38.183-4.206-52.512-11.844c-17.946-9.566-27.829-23.582-27.829-39.463   s9.883-29.897,27.829-39.463C176.159,4.206,194.808,0,214.346,0c19.537,0,38.186,4.206,52.511,11.844   c17.944,9.567,27.827,23.582,27.827,39.463s-9.883,29.896-27.827,39.463C252.527,98.408,233.878,102.614,214.346,102.614z    M214.346,19c-16.476,0-31.95,3.413-43.574,9.609c-6.635,3.538-17.767,11.189-17.767,22.697s11.131,19.16,17.766,22.697   c11.627,6.197,27.102,9.61,43.574,9.61c16.471,0,31.946-3.413,43.573-9.61c6.635-3.537,17.766-11.19,17.766-22.697   s-11.131-19.16-17.767-22.697C246.294,22.413,230.821,19,214.346,19z" />
      <path d="M348.071,154.868c-15.865-17.764-36.45-28.273-58.867-28.273c-5.417,0-5.911,3.988-10.25,13.25   c-2.904,6.199-7.287,13.583-17.803,19.189c-12.361,6.59-28.984,10.219-46.807,10.219c-17.821,0-34.445-3.629-46.805-10.219   c-10.516-5.607-15.03-12.918-16.547-19.594c-2.039-8.97-1.464-13.095-9.048-13.095c-21.741,0-45.459,10.759-61.324,28.523   c-15.742,17.623-24.41,40.811-24.41,65.289c0,34.498,12.354,83.486,33.05,131.042c9.215,21.172,19.033,39.483,28.395,52.952   c11.475,16.513,21.527,24.539,30.732,24.539c4.308,0,12.178-1.774,16.474-13.653c3.774-10.439,7.157-22.379,10.739-35.021   c8.776-30.979,20.797-73.405,38.147-73.405h1.197c7.532,0,14.448,6.823,21.149,20.862c6.94,14.533,12.255,33.994,17.399,52.836   c3.428,12.564,6.668,24.433,10.326,34.691c4.213,11.821,12.006,13.59,16.285,13.592c9.165,0,19.213-8.146,30.713-24.908   c9.334-13.606,19.172-32.096,28.444-53.467c20.802-47.937,33.22-96.556,33.22-130.06   C372.481,195.68,363.813,172.493,348.071,154.868z M323.694,343.457c-8.722,20.099-18.267,38.077-26.873,50.624   c-8.402,12.25-13.253,18.196-15.45,17.598c-0.917-0.25-1.21-1.379-1.567-2.377c-3.44-9.658-6.598-21.223-9.944-33.486   c-5.336-19.543-10.854-39.749-18.449-55.657c-9.808-20.537-21.733-30.521-36.465-30.521h-1.197   c-14.221,0-25.885,9.901-35.663,30.279c-7.568,15.774-13.285,35.953-18.818,55.49c-3.496,12.336-6.797,23.985-10.366,33.856   c-0.384,1.063-1.196,2.747-2.863,2.247c-1.761-0.528-5.174-3.706-14.447-17.048c-8.611-12.394-18.119-30.164-26.768-50.039   c-19.518-44.848-31.641-92.463-31.641-124.268c0-31.947,22.854-68.49,61.256-75.806c4.063,24.059,37.64,41.873,79.907,41.873   c42.262,0,75.837-17.811,79.908-41.865c38.402,7.342,61.256,43.875,61.256,75.797C355.508,250.99,343.319,298.236,323.694,343.457z   " />
      <path d="M230.904,134.325c0,0-3.875,0-5.167,0c-0.791,0-0.827-0.4-0.827-1.015c0-3.729,0-14.913,0-14.913   c0-3.165-2.589-5.754-5.754-5.754h-9.588c-3.165,0-5.754,2.589-5.754,5.754c0,0,0,11.185,0,14.913c0,0.552-0.098,1.015-0.765,1.015   c-1.308,0-5.229,0-5.229,0c-3.165,0-4.099,1.992-2.077,4.427l14.943,17.997c2.021,2.435,5.331,2.435,7.352,0l14.943-17.997   C235.002,136.317,234.069,134.325,230.904,134.325z" />
    </g>
  </FlatSVG>
)

export default Filling
