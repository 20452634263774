import React from 'react'
import FlatSVG from './FlatSVG'

const Implant = props => (
  <FlatSVG {...props}>
    <title>implant</title>
    <g>
      <path d="M447.633,87.315c-9.38-38.159-33.169-67.878-63.648-79.497C326.317-14.172,266.739,16.587,256,22.597    c-10.74-6.01-70.318-36.769-127.985-14.779c-30.479,11.62-54.268,41.339-63.648,79.497c-11.94,48.578,1.49,100.266,36.859,142.695    h18.269h15.139h238.642h19.229h18.269C446.143,187.581,459.573,135.893,447.633,87.315z" />
    </g>
    <g>
      <polygon points="363.866,260.008 349.387,265.948 332.327,272.938 267.409,299.567 256.03,271.808 284.799,260.008     172.523,260.008 157.113,260.008 141.704,260.008 153.123,308.427 167.323,303.317 181.532,298.207 233.351,279.548     243.51,307.777 188.462,327.596 174.253,332.706 160.053,337.826 169.073,376.064 183.272,370.955 197.482,365.835     233.351,352.925 243.51,381.144 204.412,395.224 190.202,400.333 176.003,405.453 185.012,443.692 199.222,438.582     213.421,433.462 233.351,426.293 243.51,454.522 220.361,462.851 220.361,462.861 206.152,467.971 191.942,473.081     191.942,473.091 201.122,512 306.788,512 327.357,424.743 310.518,430.802 293.678,436.862 266.799,446.542 256.64,418.323     301.898,402.033 301.898,402.023 318.728,395.964 335.577,389.904 346.267,344.565 329.417,350.625 312.578,356.685     312.578,356.695 266.799,373.174 256.64,344.945 320.798,321.846 337.637,315.786 354.476,309.727 366.206,260.008     366.456,258.948   " />
    </g>
  </FlatSVG>
)

export default Implant
